import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import '../../assets/css/main.scss';

const Layout = (props) => {
  const { title, children } = props;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="shortcut icon" href="/favicon.svg" type="image/svg+xml" />
        <link rel="shortcut icon" href="/favicon.png" type="image/png" />

        <meta name="description" content="Customized Software Solutions" />
        <meta property="og:image" content="/logo.png" />
      </Helmet>

      {children}
    </>
  );
};

Layout.propTypes = {
  title: PropTypes.string
};

Layout.defaultProps = {
  title: 'CrustByte - We Byte Big'
};

export default Layout;
